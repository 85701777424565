<template>
  <div class="row books">
    <div class="col-md-12 mt-2">
      <div class="card p-2">
        <h4 class="h3 text-center">Sales Report</h4>
        <b-row v-if="isError" class="justify-content-center mt-2">
          <b-col lg="10">
            <b-alert variant="danger" show dismissible>
              Opps .. Something is wrong
            </b-alert>
          </b-col>
        </b-row>
        <div class="card-body">
          <div class="form-group row">
            <span class="col-sm-2">Select Range</span>
            <div class="col-md-8">
              <v-date-picker
                v-model="range"
                mode="date"
                :masks="masks"
                :max-date="new Date()"
                is-range
              >
                <template v-slot="{ inputValue, inputEvents, isDragging }">
                  <div class="rows">
                    <input
                      class="flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full p-1"
                      :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                      :value="inputValue.start"
                      v-on="inputEvents.start"
                      :max-date="new Date()"
                    />

                    <span class="p-2">to</span>

                    <input
                      class="flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full p-1"
                      :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                      :value="inputValue.end"
                      v-on="inputEvents.end"
                      :max-date="new Date()"
                    />
                  </div>
                </template>
              </v-date-picker>
            </div>
            <div class="col-md-2">
              <button class="btn btn-block btn-primary" @click="actionSearch">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
          <div class="row justify-content-end">
            <div class="col-md-2 mb-1">
              <input
                type="text"
                v-model="curs"
                class="form-control"
                placeholder="14000"
              />
            </div>
            <div class="col-md-1 mb-1">
              <button
                class="btn btn-block btn-outline-danger"
                @click="actionCreateTablePDF"
              >
                <i class="fa fa-file-pdf-o"></i>
              </button>
            </div>
            <div class="col-md-1 mb-1">
              <button
                class="btn btn-block btn-outline-success"
                @click="actionDownloadRecap"
              >
                <i class="fa fa-file-excel-o" aria-hidden="true"></i>
              </button>
            </div>
            <div class="col-md-2">
              <label for="" class="col-sm-auto">Count </label>
              <strong class="col-sm-auto">
                {{ formatPriceBasic(totalRows) }}
              </strong>
            </div>
          </div>
          <table
            class="table table-striped table-bordered table-sm table-responsive-sm mt-4"
          >
            <thead>
              <tr>
                <th>No</th>
                <th>Price IDR</th>
                <th>Price USD</th>
                <th>Price PTN</th>
              </tr>
            </thead>
            <tbody v-if="!isLoading">
              <template v-for="(item, index) in items">
                <tr :key="item.id">
                  <td>{{ ++index }}</td>
                  <td v-if="item.currency_code == 'IDR'" class="text-right">
                    {{ formatPrice(item.total_amount, item.currency_code) }}
                  </td>
                  <td v-else></td>
                  <td v-if="item.currency_code == 'USD'" class="text-right">
                    {{ formatPrice(item.total_amount, item.currency_code) }}
                  </td>
                  <td v-else></td>
                  <td v-if="item.currency_code == 'PTS'" class="text-right">
                    {{ formatPrice(item.total_amount, item.currency_code) }}
                  </td>
                  <td v-else></td>
                </tr>
              </template>
            </tbody>
            <tbody v-else>
              <template>
                <tr>
                  <td colspan="10">
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong> Loading...</strong>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="1" class="text-center">Total</td>
                <td class="text-right">
                  {{ formatPrice(totalAmount, "IDR") }}
                </td>
                <td class="text-right">
                  {{ formatPrice(totalAmountD, "USD") }}
                  ( {{ formatPrice(totalAmountD * curs, "IDR") }} )
                </td>
                <td class="text-right">
                  {{ formatPrice(totalAmountPTS, "IDR") }}
                </td>
              </tr>
              <tr>
                <td colspan="3" class="text-center">Total</td>
                <td class="text-right">
                  <strong>
                    {{ formatPrice(SumAmount, "IDR") }}
                  </strong>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
var doc = new jsPDF({
  orientation: "landscape",
  page:'f4'
});
import xlsx from "xlsx";
import { mapActions, mapState } from "vuex";

export default {
  name: "Salesreport",
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      totalRows: 0,
      reports: [],
      totalAmount: 0,
      totalAmountD: 0,
      totalAmountPTS: 0,
      SumAmount: 0,
      curs: 14200,
      Dates: {
        from: moment().format("YYYY,MM,DD"),
        to: moment().format("YYYY,MM,DD"),
      },
      range: {
        start: moment().format("YYYY,MM,DD"),
        end: moment().format("YYYY,MM,DD"),
      },
      masks: {
        input: "YYYY-MM-DD",
      },
    };
  },
  watch: {
    currentPage: function() {
      this.actionSearch();
    },
    items: function() {
      if (this.items) {
        this.actionDestructor();
      }
    },
  },
  computed: {
    ...mapState({
      items: (state) => state.sales.items,
      isLoading: (state) => state.sales.isLoading,
      isError: (state) => state.sales.isError,
    }),
  },
  methods: {
    ...mapActions("sales", ["fetchReport"]),
    formatPrice(value, currency) {
      if (value) {
        var formatter = null;
        if (currency == "USD") {
          formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: currency,
          });
        } else {
          formatter = new Intl.NumberFormat("en-ID", {
            style: "currency",
            currency: currency,
          });
        }

        return formatter.format(value);
      }

      return "0";
    },
    formatDate(tgl) {
      //return moment(tgl).format("DD MMM YY hh:mm");
      return new Date(tgl).toLocaleString();
    },
    customLabel({ name }) {
      if (name) {
        return name;
      } else {
        return "Type Here";
      }
    },
    actionSearch() {
      let payload = {
        from: moment(this.range.start).format("YYYY-MM-DD"),
        to: moment(this.range.end).format("YYYY-MM-DD"),
        page: this.currentPage,
        limit: this.perPage,
      };

      try {
        this.fetchReport(payload);
      } catch (error) {
        this.isError = true;
        console.log(error);
      }
    },
    actionDestructor() {
      console.log("call ");
      let t = this.items.find((e) => {
        if (e.currency_code == "IDR") {
          return e.total_amount;
        }
        return 0;
      });
      let pts = this.items.find((e) => {
        if (e.currency_code == "PTS") {
          return e.total_amount;
        }
        return 0;
      });
      let td = this.items.find((e) => {
        if (e.currency_code == "USD") {
          return e.total_amount;
        }
        return 0;
      });

      this.totalRows = this.items.length;
      this.totalAmount = parseInt(t.total_amount);
      this.totalAmountD = parseInt(td.total_amount);
      this.totalAmountPTS = parseInt(pts.total_amount);
      let c = this.totalAmountD * this.curs;
      console.log(c);
      console.log(this.totalAmountPTS + this.totalAmount);
      this.SumAmount = this.totalAmountPTS + this.totalAmount + c;
    },
    formatPriceBasic(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    actionCreateTablePDF() {
      let datatable = [];
      var totalPagesExp = "{total_pages_count_string}";
      let n = 0;
      let payload = {
        from: moment(this.range.start).format("YYYY-MM-DD"),
        to: moment(this.range.end).format("YYYY-MM-DD"),
      };
      this.reports.forEach((e) => {
        let amount_idr = this.formatPrice(e.total_amount, e.currency_code);
        let amount_usd = this.formatPrice(e.total_amount, e.currency_code);
        let amount_pts = this.formatPrice(e.total_amount, e.currency_code);
        datatable.push({
          no: ++n,
          name: e.offer_name,
          count: e.count,
          amount_idr: amount_idr,
          amount_usd: amount_usd,
          amount_pts: amount_pts,
        });
      });
      doc.setFontSize(18);
      doc.setTextColor(100);
      doc.text(`Report Order ${payload.from} - ${payload.to}`, 14, 16);
      doc.autoTable({
        theme: "grid",
        columnStyles: {
          price: { halign: "right" },
          final_price: { halign: "right" },
        },
        body: datatable,
        startY: 20,
        showHead: "firstPage",
        rowPageBreak: "auto",
        columns: [
          { header: "No", dataKey: "no", styles: { halign: "center" } },
          { header: "Name", dataKey: "name", styles: { halign: "left" } },
          {
            header: "Price IDR",
            dataKey: "amount_idr",
            styles: { halign: "right" },
          },
          {
            header: "Price USD",
            dataKey: "amount_usd",
            styles: { halign: "right" },
          },
          {
            header: "Price PTS",
            dataKey: "amount_pts",
            styles: { halign: "right" },
          },
        ],
        didDrawPage: function(data) {
          var str = "Page " + doc.internal.getNumberOfPages();
          if (typeof doc.putTotalPages === "function") {
            str = str + " of " + totalPagesExp;
          }
          doc.setFontSize(10);
          var pageSize = doc.internal.pageSize;
          var pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          doc.text(str, data.settings.margin.left, pageHeight - 10);
        },
      });

      // doc.text("Footer Laporan Orders", 14, doc.lastAutoTable.finalY + 10);
      if (typeof doc.putTotalPages === "function") {
        doc.putTotalPages(totalPagesExp);
      }

      doc.save(`reports` + this.range.start + ".pdf");
    },
    actionDownloadRecap() {
      const wb = xlsx.utils.book_new();
      let payload = {
        from: moment(this.range.start).format("YYYY-MM-DD"),
        to: moment(this.range.end).format("YYYY-MM-DD"),
      };
      wb.Props = {
        Title: "Report Buffet",
        Subject: "Report",
        Author: "Digital GAM",
        CreatedDate: new Date(),
      };
      let parseData = [];
      let data = this.reports;
      if (data.length < 1) {
        this.$notify({
          group: "foo",
          type: "error",
          title: "Opss .. ",
          text: "Tidak ada data yang di rekap",
        });
      } else {
        let Attributes = [
          "No",
          "Offer Name",
          "Count",
          "Price IDR",
          "Price USD",
          "Price POINT",
        ];
        let wscols = [
          { wch: 8 },
          { wch: 35 },
          { wch: 10 },
          { wch: 30 },
          { wch: 30 },
          { wch: 30 },
          { wch: 30 },
        ];
        parseData.push(Attributes);
        let n = 0;
        data.forEach((e) => {
          let dataPeserta = [
            ++n,
            e.offer_name,
            e.count,
            e.currency_code == "IDR" ? e.total_amount : 0,
            e.currency_code == "USD" ? e.total_amount : 0,
            e.currency_code == "PTS" ? e.total_amount : 0,
          ];
          parseData.push(dataPeserta);
          console.log(dataPeserta);
        });
        // parseData.push("-", "TOTAL", "", total);
        const ws = xlsx.utils.aoa_to_sheet(parseData);
        ws["!cols"] = wscols;
        let name = "BUFFET-" + payload.from + "-to-" + payload.to;
        xlsx.utils.book_append_sheet(wb, ws, "REPORT");
        xlsx.writeFile(wb, "REPORT_" + name + ".xlsx");
      }
    },
  },
};
</script>
<style scoped>
.books {
  font-family:"Avenir Next" ,"effra", Helvetica, Arial, sans-serif;
}
</style>
